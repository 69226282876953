import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import CallToActionBackground from "../../img/call-to-action-background.png";
import footerdata from "../../data/footerdata.json";
import "react-whatsapp-chat-widget/index.css";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class Calltoaction extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.client;
    let PaddingTop = this.props.PaddingTop
      ? this.props.PaddingTop
      : "pd-top-90";
    let PaddingBottom = this.props.PaddingBottom
      ? this.props.PaddingBottom
      : "pd-bottom-100";

    const inlineStyle = {
      backgroundImage: `url(${CallToActionBackground})`,
    };

    return (
      <div
        // style={{ marginBottom: "600px" }}
        className="call-to-action-area pd-top-100"
      >
        <div className="container">
          <div className="call-to-action style-two" style={inlineStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              className="cta-content"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "220px",
                  margin: "20px",
                }}
              >
                <h4 style={{ color: "#fff" }}>Asistență virtuală 24/7</h4>
                <h4 style={{ color: "#fff" }}>GRATUIT</h4>

                <>
                  <a
                    href="https://wa.me/784740440"
                    // className="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      // position: "fixed",
                      // width: "50px",
                      height: "50px",
                      // bottom: "100px",
                      // right: "30px",
                      backgroundColor: "#25d366",
                      color: "#FFF",
                      borderRadius: "50px",
                      textAlign: "center",
                      fontSize: "26px",
                      boxShadow: "2px 2px 3px #999",
                      zIndex: "100",
                    }}
                  >
                    <i className="fa fa-whatsapp whatsapp-icon">
                      &nbsp;Whatsapp
                    </i>
                  </a>
                </>
                {/*<ul className="social-icon">*/}
                {/*  {footerdata.socialicon.map((item, i) => (*/}
                {/*    <li key={i}>*/}
                {/*      <a href={item.url} target="_blank">*/}
                {/*        <i className={item.icon} />*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*  ))}*/}
                {/*</ul>*/}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "220px",
                  margin: "20px",
                }}
              >
                <h4 style={{ color: "#fff" }}>Sună-ne la</h4>
                <a href="tel:+40 784 740 440" target="_blank">
                  <h3 className="title">+40 784 740 440</h3>
                </a>
                <h4 style={{ color: "#fff" }}>sau</h4>

                <a
                  style={{
                    backgroundColor: "#FDA950",
                    minWidth: "220px",
                    color: "#FFFFFF",
                  }}
                  className="btn btn-white mb-2 mb-sm-0 mr-xl-3 mr-0"
                  onClick={() => scrollToSection("contacteaza-ne")}
                >
                  Solicită o programare
                </a>
                {/*<ul className="social-icon">*/}
                {/*  {footerdata.socialicon.map((item, i) => (*/}
                {/*    <li key={i}>*/}
                {/*      <a href={item.url} target="_blank">*/}
                {/*        <i className={item.icon} />*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*  ))}*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calltoaction;
