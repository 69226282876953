import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PersonIcon from "@mui/icons-material/Person";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class Mission extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.missiontwo;

    return (
      <section id="ghidul-vanzatorului">
        <div className="mission-vission-area pd-top-100 pd-bottom-70">
          <div className="container">
            <div
              style={{ marginBottom: "60px" }}
              className="section-title text-lg-center"
            >
              <h2 className="title">
                Platforma ce iți oferă{" "}
                <span
                  style={{ color: "rgb(253, 169, 80)", fontWeight: "bold" }}
                >
                  servicii complete
                </span>{" "}
                pentru a vinde proprietăți și imobile <strong>singur</strong> și{" "}
                <strong>profesionist</strong>.
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="row justify-content-center"
            >
              {/*{data.items.map((item, i) => (*/}
              <div
                style={{ minWidth: "340px" }}
                className={
                  "col-xl-3 col-lg-4 col-sm-6 mb-3 " + data.items[0].class
                }
              >
                <div className="single-intro text-sm-center">
                  <div className="thumb">
                    <LightbulbIcon
                      style={{ color: "#fff", marginTop: "14px" }}
                      fontSize="large"
                    />
                  </div>
                  <div className="details">
                    <h4 style={{ marginBottom: "0px" }} className="title">
                      <span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          GHIDUL VÂNZĂTORULUI
                        </span>{" "}
                        <br /> INTELIGENT
                      </span>
                    </h4>
                    <span>pas cu pas</span>
                    <h4 style={{ marginTop: "10px" }} className="title">
                      <span style={{ color: "rgb(253, 169, 80)" }}>
                        ONLINE - GRATUIT
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div
                style={{ minWidth: "340px" }}
                className={
                  "col-xl-3 col-lg-4 col-sm-6 mb-3 " + data.items[0].class
                }
              >
                <div className="single-intro text-sm-center">
                  <div className="thumb">
                    <PersonIcon
                      style={{ color: "#fff", marginTop: "14px" }}
                      fontSize="large"
                    />
                  </div>
                  <div className="details">
                    <h4 style={{ marginBottom: "0px" }} className="title">
                      <span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          ASISTENT IMOBILIAR
                        </span>{" "}
                        <br /> VIRTUAL
                      </span>
                    </h4>
                    <span>disponibil 24/7</span>
                    <h4 style={{ marginTop: "10px" }} className="title">
                      <span
                        style={{
                          color: "rgb(253, 169, 80)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        WHATSAPP - GRATUIT
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div
                style={{ minWidth: "340px" }}
                className={
                  "col-xl-3 col-lg-4 col-sm-6 mb-3 " + data.items[0].class
                }
              >
                <div className="single-intro text-sm-center">
                  <div className="thumb">
                    <BusinessCenterIcon
                      style={{ color: "#fff", marginTop: "14px" }}
                      fontSize="large"
                    />
                  </div>
                  <div className="details">
                    <h4 style={{ marginBottom: "0px" }} className="title">
                      <span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {" "}
                          PACHETE PROFESIONALE
                        </span>{" "}
                        <br /> IMOBILIARE
                      </span>
                    </h4>
                    <span>servicii complete</span>
                    <h4 style={{ marginTop: "10px" }} className="title">
                      <a
                        onClick={() => {
                          scrollToSection("pachete");
                        }}
                        style={{
                          color: "rgb(253, 169, 80)",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        VEZI SERVICII
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
              {/*// ))}*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Mission;
