import React, { Component } from "react";
import { Link } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import BackgroundImage from "../../img/banner1.png";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class Banner extends Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".single-select").length) {
      $(".single-select").niceSelect();
    }
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.banner;

    const inlineStyle = {
      backgroundImage: `url(${BackgroundImage})`,
      marginTop: "-60px",
    };

    return (
      <div id="acasa" className="banner-area jarallax" style={inlineStyle}>
        <div className="container">
          <div className="banner-inner-wrap">
            <div className="row">
              <div className="col-12">
                <div className="banner-inner">
                  {/*TODO Uncomment only the element below*/}
                  {/*<h5 className="sub-title">{data.subtitle}</h5>*/}
                  {/*TODO Uncomment only the element below*/}
                  {/*<h1 className="title">*/}
                  {/*  {data.title1} <br /> {data.title2}*/}
                  {/*</h1>*/}
                  {/*TODO COMMENT only the element below*/}
                  <h1 className="title">În curând...</h1>
                </div>
              </div>
              {/*CERE DETALII*/}
              {/*<div*/}
              {/*    style={{*/}
              {/*        marginBottom: "200px",*/}
              {/*        // display: "flex",*/}
              {/*        // flexDirection: "column",*/}
              {/*        // backgroundColor: "red",*/}
              {/*        // minWidth: "200px",*/}
              {/*    }}*/}
              {/*    className="col-xl-2 col-lg-4 col-md-4 readeal-top"*/}
              {/*>*/}
              {/*    <a*/}
              {/*        className="btn btn-yellow"*/}
              {/*        style={{cursor: "pointer", color: "#fff", marginBottom: "16px", marginTop: "16px"}}*/}
              {/*        onClick={() => scrollToSection("contacteaza-ne")}*/}
              {/*    >*/}
              {/*        Cere Detalii*/}
              {/*    </a>*/}

              {/*    <p style={{minWidth: "300px", fontSize: "22px"}}>Sau sună la: <a style={{textDecoration: "underline"}} href="tel:+40 784 925 925" target="_blank"><strong>+40 784 925 925</strong></a></p>*/}
              {/*</div>*/}

              {/*<div className="col-12">*/}
              {/*  <div className="banner-search-wrap">*/}
              {/*    <ul className="nav nav-tabs rld-banner-tab">*/}
              {/*      <li className="nav-item">*/}
              {/*        <a className="nav-link active" data-toggle="tab" href="#tabs_1">For Buy</a>*/}
              {/*      </li>*/}
              {/*      <li className="nav-item">*/}
              {/*        <a className="nav-link" data-toggle="tab" href="#tabs_2">For Rent</a>*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*    <div className="tab-content">*/}
              {/*      <div className="tab-pane fade show active" id="tabs_1">*/}
              {/*        <div className="rld-main-search">*/}
              {/*          <div className="row">*/}
              {/*            <div className="col-xl-4 col-lg-6 col-md-6">*/}
              {/*              <div className="rld-single-input left-icon">*/}
              {/*                <input type="text" placeholder="Entry Landmark Location" />*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-6 col-md-6">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>All Properties</option>*/}
              {/*                  <option value={2}>Properties 1</option>*/}
              {/*                  <option value={3}>Properties 2</option>*/}
              {/*                  <option value={3}>Properties 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>Room</option>*/}
              {/*                  <option value={2}>Room 1</option>*/}
              {/*                  <option value={3}>Room 2</option>*/}
              {/*                  <option value={3}>Room 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>Any Price</option>*/}
              {/*                  <option value={2}>Price 1</option>*/}
              {/*                  <option value={3}>Price 2</option>*/}
              {/*                  <option value={3}>Price 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">*/}
              {/*              <Link className="btn btn-yellow" to="/search-list">SEARCH NOW</Link>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="tab-pane fade" id="tabs_2">*/}
              {/*        <div className="rld-main-search">*/}
              {/*          <div className="row">*/}
              {/*            <div className="col-xl-4 col-lg-6 col-md-6">*/}
              {/*              <div className="rld-single-input left-icon">*/}
              {/*                <input type="text" placeholder="Entry Landmark Location" />*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-6 col-md-6">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>All Properties</option>*/}
              {/*                  <option value={2}>Properties 1</option>*/}
              {/*                  <option value={3}>Properties 2</option>*/}
              {/*                  <option value={3}>Properties 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>Room</option>*/}
              {/*                  <option value={2}>Room 1</option>*/}
              {/*                  <option value={3}>Room 2</option>*/}
              {/*                  <option value={3}>Room 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4">*/}
              {/*              <div className="rld-single-select">*/}
              {/*                <select className="select single-select">*/}
              {/*                  <option value={1}>Any Price</option>*/}
              {/*                  <option value={2}>Price 1</option>*/}
              {/*                  <option value={3}>Price 2</option>*/}
              {/*                  <option value={3}>Price 3</option>*/}
              {/*                </select>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">*/}
              {/*              <Link className="btn btn-yellow" to="/search-list">SEARCH NOW</Link>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
