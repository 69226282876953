import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import Service from "./section-components/service";
import Explore from "./section-components/explore";
import FeaturedProperties from "./section-components/featured-properties";
import Ads from "./section-components/ads";
import PropertiesByCities from "./section-components/properties-by-cities";
import RecentProperties from "./section-components/recent-properties";
import FeaturedPorject from "./section-components/featured-project";
import WhyChooseUs from "./section-components/why-choose-us";
import OurPartner from "./section-components/our-partner";
import Footer from "./global-components/footer";
import AboutUs from "./section-components/about-us";
import ServiceTwo from "./section-components/service-two";
import Contact from "./contact";
import CalltoAction from "./section-components/calltoaction";
import Pricing from "./pricing";
import PricingSection from "../../src/components/section-components/pricing";
import RoomSpacing from "./section-components/room-spacing";
import CounterComponent from "./counter-card/counter-card";
import Mission from "./section-components/mission-two";

const Home_V1 = () => {
  return (
    <div>
      {/*TODO Uncomment only the line below*/}
      {/*<Navbar />*/}
      <Banner />
      {/*TODO Uncomment only the line below*/}
      {/*<Service />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<Mission />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<CalltoAction />*/}
      {/*<Pricing />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<PricingSection />*/}
      {/*<RoomSpacing />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<CounterComponent />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<CalltoAction />*/}

      {/*TODO Add later if needed*/}
      {/*<AboutUs />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<ServiceTwo />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<Contact />*/}
      {/*<Explore />*/}
      {/*<FeaturedProperties />*/}
      {/*<Ads />*/}
      {/*<PropertiesByCities />*/}
      {/*<RecentProperties />*/}
      {/*<FeaturedPorject />*/}
      {/*<WhyChooseUs />*/}
      {/*<OurPartner />*/}
      {/*TODO Uncomment only the line below*/}
      {/*<Footer />*/}
    </div>
  );
};

export default Home_V1;
