import React, { Component, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../img/self_sell_logo_black.png";
import footerdata from "../../data/footerdata.json";
import tiktokLogo from "../../img/tiktok.png";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

const Navbar = () => {
  const buttonRef = useRef(null);

  const handleClickButton = () => {
    // Programmatically trigger a click on the button
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  let anchor = "";

  return (
    <div>
      <div className="navbar-area">
        <nav className="navbar navbar-area navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button
                style={{ bottom: -50 }}
                className="menu toggle-btn d-block d-lg-none"
                data-toggle="collapse"
                data-target="#realdeal_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
                ref={buttonRef}
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo readeal-top">
              <Link to="/">
                <img style={{ height: "64px" }} src={LogoImage} alt="logo" />
              </Link>
            </div>
            {/*<div className="nav-right-part nav-right-part-mobile">*/}
            {/*        <a className="btn btn-yellow" style={{cursor: "pointer", color: "#fff"}} onClick={() => scrollToSection('contacteaza-ne')}>CONTACTEAZĂ-NE</a>*/}
            {/*</div>*/}
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul
                // style={{
                //   display: "flex",
                //   flexDirection: "row",
                //   flexWrap: "no-wrap",
                //   justifyContent: "flex-end",
                //   alignItems: "center",
                // }}
                className="navbar-nav menu-open readeal-top"
              >
                {/*<li*/}
                {/*  style={{ padding: "5px", margin: "5px" }}*/}
                {/*  // className="menu-item-has-children current-menu-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("acasa")}*/}
                {/*  >*/}
                {/*    Acasă*/}
                {/*  </a>*/}
                {/*  /!*<ul className="sub-menu">*!/*/}
                {/*  /!*  <li><Link to="/">Home 01</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/home-v2">Home 02</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/home-v3">Home 03</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/home-v4">Home 04</Link></li>*!/*/}
                {/*  /!*</ul>*!/*/}
                {/*</li>*/}
                {/*<ul className="social-icon">*/}
                {/*{footerdata.socialicon.map((item, i) => (*/}
                {windowSize.width > 991 ? (
                  <>
                    {" "}
                    <li className="social-icon">
                      {footerdata.socialicon[0].icon === "tiktok" ? (
                        <a href={footerdata.socialicon[0].url} target="_blank">
                          <img style={{ width: "14px" }} src={tiktokLogo} />
                        </a>
                      ) : (
                        <a href={footerdata.socialicon[0].url} target="_blank">
                          <i className={footerdata.socialicon[0].icon} />
                        </a>
                      )}
                    </li>
                    <li className="social-icon">
                      {footerdata.socialicon[1].icon === "tiktok" ? (
                        <a href={footerdata.socialicon[1].url} target="_blank">
                          <img style={{ width: "14px" }} src={tiktokLogo} />
                        </a>
                      ) : (
                        <a href={footerdata.socialicon[1].url} target="_blank">
                          <i className={footerdata.socialicon[1].icon} />
                        </a>
                      )}
                    </li>
                    <li className="social-icon">
                      {footerdata.socialicon[2].icon === "tiktok" ? (
                        <a href={footerdata.socialicon[2].url} target="_blank">
                          <img style={{ width: "14px" }} src={tiktokLogo} />
                        </a>
                      ) : (
                        <a href={footerdata.socialicon[2].url} target="_blank">
                          <i className={footerdata.socialicon[2].icon} />
                        </a>
                      )}
                    </li>
                    <li className="social-icon">
                      {footerdata.socialicon[3].icon === "tiktok" ? (
                        <a href={footerdata.socialicon[3].url} target="_blank">
                          <img style={{ width: "14px" }} src={tiktokLogo} />
                        </a>
                      ) : (
                        <a href={footerdata.socialicon[3].url} target="_blank">
                          <i className={footerdata.socialicon[3].icon} />
                        </a>
                      )}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a
                        // data-toggle="collapse"
                        // data-target="#realdeal_main_menu"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          handleClickButton();
                          scrollToSection("ghidul-vanzatorului");
                        }}
                      >
                        Ghidul Vânzătorului
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          handleClickButton();
                          scrollToSection("pachete");
                        }}
                      >
                        Pachete SelfSell
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          cursor: "pointer",
                          fontSize: "14",
                        }}
                        onClick={() => {
                          handleClickButton();
                          scrollToSection("contacteaza-ne");
                        }}
                      >
                        Contact
                      </a>
                    </li>
                  </>
                )}

                {/*{windowSize <= 991 ? (*/}
                {/*  <>*/}
                {/*    {" "}*/}
                {/*    <li*/}
                {/*      style={{ marginRight: "0px" }}*/}
                {/*      className="nav-right-part nav-right-part-desktop readeal-top"*/}
                {/*    >*/}
                {/*      <a*/}
                {/*        className="btn btn-yellow"*/}
                {/*        style={{*/}
                {/*          cursor: "pointer",*/}
                {/*          color: "#fff",*/}
                {/*          paddingLeft: "12px",*/}
                {/*          paddingRight: "12px",*/}
                {/*          fontSize: "14px",*/}
                {/*        }}*/}
                {/*        // onClick={() => scrollToSection("contacteaza-ne")}*/}
                {/*        href="tel:+40 784 740 440"*/}
                {/*        target="_blank"*/}
                {/*      >*/}
                {/*        +40 784 740 440*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*  </>*/}
                {/*) : null}*/}

                {/*// ))}*/}
                {/*</ul>*/}
                {/*<li*/}
                {/*  style={{ padding: "5px", margin: "5px" }}*/}
                {/*  // className="menu-item-has-children"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("despre-noi")}*/}
                {/*  >*/}
                {/*    Despre noi*/}
                {/*  </a>*/}
                {/*  /!*<ul className="sub-menu">*!/*/}
                {/*  /!*   <li><Link to="/property">Property</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/availavbe-property">Propertys Availavbe</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/properties-by-city">Property By City</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/recent-properties">Property Recenty</Link></li>*!/*/}
                {/*  /!*  <li><Link to="/property-details">Property Details</Link></li>*!/*/}
                {/*  /!*</ul>*!/*/}
                {/*</li>*/}
                {/*<li style={{ padding: "5px", margin: "5px" }}>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("de-ce-noi")}*/}
                {/*  >*/}
                {/*    De ce noi*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li style={{ padding: "5px", margin: "5px" }}>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("de-ce-noi")}*/}
                {/*  >*/}
                {/*    Ghid Vânzător*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li style={{ padding: "5px", margin: "5px" }}>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("de-ce-noi")}*/}
                {/*  >*/}
                {/*    Servicii*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li style={{ padding: "5px", margin: "5px" }}>*/}
                {/*  <a*/}
                {/*    style={{ cursor: "pointer", fontSize: "14px" }}*/}
                {/*    onClick={() => scrollToSection("de-ce-noi")}*/}
                {/*  >*/}
                {/*    Galerie*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li
                  style={{ marginRight: "0px" }}
                  className="nav-right-part nav-right-part-desktop readeal-top"
                >
                  <a
                    className="btn btn-yellow"
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      fontSize: "14px",
                    }}
                    // onClick={() => scrollToSection("contacteaza-ne")}
                    href="tel:+40 784 740 440"
                    target="_blank"
                  >
                    +40 784 740 440
                  </a>
                </li>
                {/*<li className="menu-item-has-children">*/}
                {/*  <a href="#">News</a>*/}
                {/*  <ul className="sub-menu">*/}
                {/*    <li><Link to="/news">News</Link></li>*/}
                {/*    <li><Link to="/news-details">News Details</Link></li>*/}
                {/*  </ul>*/}
                {/*</li>*/}
                {/*<li><Link to="/contact">Contact Us</Link></li>*/}
              </ul>
            </div>
            {/*<div className="nav-right-part nav-right-part-desktop readeal-top">*/}
            {/*  <span className="btn btn-yellow" ><a style={{cursor: "pointer"}} onClick={() => scrollToSection('contacteaza-ne')}>CONTACTEAZĂ-NE</a></span>*/}
            {/*</div>*/}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
