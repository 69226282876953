import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
// import GradingIcon from "@mui/icons-material/Grading";
// import VideocamIcon from "@mui/icons-material/Videocam";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PachetDeBazaImage from "../../img/de-baza.png";
import PachetFotoVideo from "../../img/foto-video.png";
import PachetCompletPro from "../../img/complet-pro.png";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class Service extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.pricing;

    return (
      <section
        id="pachete"
        style={{
          marginTop: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
          className="container"
        >
          {/*<div className="service-slider">*/}
          {data.items.map((item, i) => (
            <div
              style={{
                marginBottom: "20px",
                marginRight: "10px",
                marginLeft: "10px",
                width: "300px",
              }}
              key={i}
              className="item"
            >
              <div
                style={{
                  minHeight: "700px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor:
                    i === 0 ? "#ECEBE7" : i === 1 ? "#B1E3A3" : "#FFF3C1",
                }}
                className="single-service text-center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ height: "100px" }}>
                    <h4>
                      {i === 0 ? (
                        <span>
                          <span
                            style={{ fontSize: "16px", fontWeight: "normal" }}
                          >
                            PACHET
                          </span>{" "}
                          <br />
                          DE BAZĂ
                        </span>
                      ) : i === 1 ? (
                        <span>
                          <span
                            style={{ fontSize: "16px", fontWeight: "normal" }}
                          >
                            PACHET
                          </span>{" "}
                          <br />
                          FOTO/VIDEO
                        </span>
                      ) : (
                        <span>
                          <span
                            style={{ fontSize: "16px", fontWeight: "normal" }}
                          >
                            PACHET
                          </span>{" "}
                          <br />
                          COMPLET PRO
                        </span>
                      )}
                    </h4>
                  </div>
                  {/*<div className="thumb double-img">*/}
                  {/*<img src={publicUrl + item.icon} alt="icons" />*/}
                  {/*</div>*/}
                  {i === 0 ? (
                    <img
                      src={PachetDeBazaImage}
                      style={{
                        width: "80px",
                        color: "rgb(253, 169, 80)",
                      }}
                    />
                  ) : i === 1 ? (
                    <img
                      src={PachetFotoVideo}
                      style={{ width: "80px", color: "rgb(253, 169, 80)" }}
                    />
                  ) : i === 2 ? (
                    <img
                      src={PachetCompletPro}
                      style={{ width: "80px", color: "rgb(253, 169, 80)" }}
                    />
                  ) : null}
                  <h5 style={{ marginBottom: "20px" }}>{item?.price}</h5>

                  {/*<div>*/}
                  {item?.features?.map((feat) => {
                    return <p>✓ {feat}</p>;
                  })}
                </div>
                <div>
                  <a
                    style={{ marginTop: "30px", color: "#fff" }}
                    className="btn btn-yellow"
                    onClick={() => scrollToSection(item.url)}
                  >
                    {item.btntxt}
                  </a>
                  <div style={{ marginTop: "30px" }}>
                    <a href="https://wa.me/784740440" target="_blank">
                      <div
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Chat Agent Imobiliar
                      </div>
                    </a>
                    <a onClick={() => scrollToSection("contacteaza-ne")}>
                      <div
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Fă-ți programare
                      </div>
                    </a>
                    <a href="tel:0040784740440" target="_blank">
                      <div
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Sună direct
                      </div>
                    </a>
                  </div>
                </div>

                {/*</div>*/}
                {/*<div className="details readeal-top">*/}
                {/*  <h5 style={{ minHeight: "180px" }}>{item.title}</h5>*/}
                {/*  /!*<a style={{cursor: "pointer"}} onClick={() => scrollToSection('despre-noi')}>Despre noi</a>*!/*/}
                {/*  /!*<a*!/*/}
                {/*  /!*  style={{ cursor: "pointer" }}*!/*/}
                {/*  /!*  onClick={() => scrollToSection("contacteaza-ne")}*!/*/}
                {/*  /!*>*!/*/}
                {/*  /!*  {item.btntxt} <i className="la la-arrow-right" />*!/*/}
                {/*  /!*</a>*!/*/}
                {/*</div>*/}
              </div>
            </div>
          ))}
          {/*</div>*/}
        </div>
      </section>
    );
  }
}

export default Service;
