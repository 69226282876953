import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ margin: "40px" }}>
      <h3 style={{ textDecoration: "underline" }}>
        Politica de Confidențialitate
      </h3>

      <h4>Date Colectate</h4>
      <p>
        Când vizitați site-ul nostru de prezentare, nu colectăm automat date cu
        caracter personal. Cu toate acestea, puteți alege să ne furnizați
        voluntar date cu caracter personal prin completarea unui formular de
        contact sau prin trimiterea unui e-mail. Datele cu caracter personal pe
        care le colectăm pot include: <br /> Numele dvs. <br />
        Adresa de e-mail. <br />
        Numărul de telefon.
      </p>

      <h4>Scopul colectării datelor</h4>
      <p>
        Colectăm datele dvs. personale în scopul de a răspunde la solicitările
        dvs. și de a vă furniza informațiile sau asistența solicitată în
        legătură cu serviciile noastre. Nu vom utiliza datele dvs. personale în
        scopuri de marketing sau publicitate fără consimțământul dvs. expres.
      </p>

      <h4>Folosirea Datelor Personale</h4>
      <p>
        Datele personale pe care le furnizați vor fi folosite numai în scopul
        specific pentru care le-ați furnizat, cum ar fi pentru a vă răspunde la
        întrebări sau solicitări.
      </p>

      <h4>Protejarea datelor</h4>
      <p>
        Ne angajăm să protejăm datele dvs. personale și să păstrăm
        confidențialitatea acestora. Folosim măsuri tehnice și organizatorice
        adecvate pentru a proteja datele împotriva accesului neautorizat,
        pierderii sau alterării.
      </p>

      <h4>Păstrarea datelor</h4>
      <p>
        Păstrăm datele dvs. personale numai atâta timp cât este necesar pentru
        scopurile pentru care au fost colectate sau în conformitate cu cerințele
        legale.
      </p>

      <h4>Drepturile dvs.</h4>
      <p>
        Aveți dreptul să solicitați acces la datele dvs. personale pe care le
        deținem și să le corectați sau să le ștergeți în măsura în care este
        permis de lege. De asemenea, aveți dreptul de a vă retrage
        consimțământul acordat pentru prelucrarea datelor în orice moment.
      </p>

      <h4>Transferul datelor</h4>
      <p>
        Nu transferăm datele dvs. personale către terți fără consimțământul
        dvs., cu excepția cazurilor în care este necesar pentru a răspunde la
        solicitările dvs. sau pentru a ne conforma cerințelor legale.
      </p>

      <h4>Schimbări ale Politicii de Confidențialitate</h4>
      <p>
        Ne rezervăm dreptul de a modifica această Politică de Confidențialitate
        în orice moment. Orice modificări vor fi afișate pe acest site și vor
        deveni efective odată cu publicarea lor.
      </p>

      <h4>Contact</h4>
      <p>
        Dacă aveți întrebări sau preocupări cu privire la această Politică de
        Confidențialitate, vă rugăm să ne contactați prin intermediul
        informațiilor de contact furnizate pe site-ul nostru de prezentare.
      </p>

      <h5>Data Ultimei Actualizări: 21.10.2023</h5>
    </div>
  );
};

export default PrivacyPolicy;
