import React, { Component, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class Service extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.services;

    return (
      <div className="service-area h1-service-slider-area">
        <div className="container">
          <div className="service-slider">
            {data.items.map((item, i) => (
              <div>
                <div key={i} className="item">
                  <div className="single-service text-center">
                    <div className="thumb double-img">
                      <img src={publicUrl + item.icon} alt="icons" />
                    </div>
                    <div className="details readeal-top">
                      <p
                        style={{
                          minHeight: "180px",
                          color: "#000",
                          fontSize: "21px",
                          lineHeight: "29px",
                        }}
                      >
                        {i === 0 ? (
                          <span>
                            <strong>Consultanță imobiliare 24/7</strong>, din
                            confortul casei tale
                          </span>
                        ) : i === 1 ? (
                          <span>
                            <strong>Prezentare foto/video profesională</strong>,
                            oriunde în țară
                          </span>
                        ) : i === 2 ? (
                          <span>
                            <strong>Ghidul vânzătorului inteligent</strong>,
                            pentru transparență și control
                          </span>
                        ) : (
                          <span>
                            <strong>
                              Analiză de piață și recomandare preț
                            </strong>
                            , conform obiectivelor tale
                          </span>
                        )}
                      </p>
                      {/*<a style={{cursor: "pointer"}} onClick={() => scrollToSection('despre-noi')}>Despre noi</a>*/}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => scrollToSection("contacteaza-ne")}
                      >
                        {item.btntxt} <i className="la la-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
