import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

class AboutUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.aboutus;

    return (
      <section
        id="despre-noi"
        style={{ paddingTop: "100px" }}
        className="about-area pd-bottom-90"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="shape-image-list-wrap">
                <div className="shape-image-list left-top">
                  <img
                    className="shadow-img"
                    style={{ filter: "brightness(90%)" }}
                    src={publicUrl + data.image}
                    alt={imagealt}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                <h5 className="sub-title">{data.subtitle}</h5>
                <h2 className="title">{data.title}</h2>
                <p>{data.content}</p>
                <a
                  className="btn btn-yellow"
                  style={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => scrollToSection("contacteaza-ne")}
                >
                  {data.btn_text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;
