import React, { Component } from "react";
import { Link } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
import LogoImage from "../../img/self_sell_logo_black.png";
import tiktokLogo from "../../img/tiktok.png";

class Footer_v1 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";
    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + footerdata.footerbg + ")",
    };

    return (
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-sm-4">
                <a
                  style={{ maxWidth: "300px", width: "300px" }}
                  className="footer-logo"
                  href="#"
                >
                  <img
                    style={{ width: "900px", maxWidth: "200px" }}
                    src={LogoImage}
                    alt={imgattr}
                  />
                </a>
              </div>
              <div className="col-sm-8">
                <div className="footer-social text-sm-right">
                  <span>URMĂREȘTE-NE PE</span>
                  <ul className="social-icon">
                    {footerdata.socialicon.map((item, i) => (
                      <li className="social-icon" key={i}>
                        {item.icon === "tiktok" ? (
                          <a href={item.url} target="_blank">
                            <img style={{ width: "14px" }} src={tiktokLogo} />
                          </a>
                        ) : (
                          <a href={item.url} target="_blank">
                            <i className={item.icon} />
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              {/*<div className="col-lg-3 col-sm-6">*/}
              {/*  <div className="widget widget_nav_menu">*/}
              {/*    <h4 className="widget-title">{ footerdata.popularserces.title }</h4>*/}
              {/*    <ul>*/}
              {/*      { footerdata.popularserces.links.map( ( item, i )=>*/}
              {/*          <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>*/}
              {/*       ) }*/}

              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="col-lg-3 col-sm-6">*/}
              {/*  <div className="widget widget_nav_menu">*/}
              {/*    <h4 className="widget-title">{ footerdata.Homepress.title }</h4>*/}
              {/*    <ul>*/}
              {/*      { footerdata.Homepress.links.map( ( item, i )=>*/}
              {/*         <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>*/}
              {/*      ) }*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-3 col-sm-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                  <ul>
                    {/*{ footerdata.quicklink.links.map( ( item, i )=>*/}
                    <li className="readeal-top">
                      <a href={process.env.PUBLIC_URL + "/"}>Acasă</a>
                    </li>
                    <li className="readeal-top">
                      <Link to={"/politica-confidentialitate"}>
                        Politica de confidențialitate
                      </Link>
                    </li>
                    {/*// ) }*/}
                  </ul>
                </div>
              </div>
              {/*<div className="col-lg-3 col-sm-6">*/}
              {/*  <form className="widget widget-subscribe" method="GET">*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" name="name" placeholder="Full Name" />*/}
              {/*    </div>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" name="email" placeholder="Your@email.com" />*/}
              {/*    </div>*/}
              {/*    <button className="btn btn-yellow w-100">Subscribe</button>*/}
              {/*  </form>*/}
              {/*</div>*/}
            </div>
          </div>
          <div
            className="copy-right text-center"
            dangerouslySetInnerHTML={{ __html: footerdata.copyrighttext }}
          ></div>
        </div>
      </footer>
    );
  }
}

export default Footer_v1;
