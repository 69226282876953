import React from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import PachetDeBazaImage from "../../img/de-baza.png";
import PachetFotoVideo from "../../img/foto-video.png";
import PachetCompletPro from "../../img/complet-pro.png";

function RoomSpacing(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.roomspacing;

  return (
    <div
    // style={{ display: "flex", flexDirection: "column", marginTop: "260px" }}
    >
      {data?.items.map((item, i) => (
        <section id={`${item?.sectionName}`}>
          <div className="apartments-area bg-gray">
            <div className="container">
              <div className="apartments-slider-2">
                <div
                  style={{ marginTop: "40px", marginBottom: "30px" }}
                  key={i}
                  className="item"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="details">
                        <div
                          style={{
                            backgroundColor:
                              i === 0
                                ? "#ECEBE7"
                                : i === 1
                                ? "#B1E3A3"
                                : "#FFF3C1",
                            padding: "16px",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <span>
                            {i === 0 ? (
                              <img
                                src={PachetDeBazaImage}
                                style={{
                                  width: "80px",
                                  color: "rgb(253, 169, 80)",
                                }}
                              />
                            ) : i === 1 ? (
                              <img
                                src={PachetFotoVideo}
                                style={{
                                  width: "80px",
                                  color: "rgb(253, 169, 80)",
                                }}
                              />
                            ) : (
                              <img
                                src={PachetCompletPro}
                                style={{
                                  width: "80px",
                                  color: "rgb(253, 169, 80)",
                                }}
                              />
                            )}
                          </span>
                          {/*<h4 style={{ color: "red" }}>{item.subtitle}</h4>*/}
                          <h2
                            // style={{ color: "rgb(253, 169, 80)" }}
                            className="title"
                          >
                            {/*{i === 0 ? (*/}
                            {/*  <img*/}
                            {/*    src={PachetDeBazaImage}*/}
                            {/*    style={{*/}
                            {/*      width: "80px",*/}
                            {/*      color: "rgb(253, 169, 80)",*/}
                            {/*    }}*/}
                            {/*  />*/}
                            {/*) : null}*/}
                            {item.title}
                          </h2>
                          <p style={{ fontSize: "18px" }}>
                            {i === 0 ? (
                              <p>
                                Căutați expertiză în analiza pieței imobiliare
                                și sprijin în procesul de vânzare a proprietății
                                dumneavoastră?
                              </p>
                            ) : i === 1 ? (
                              <p>
                                Ați decis să vă vindeți singur proprietatea și
                                doriți să maximizați șansele de vânzare prin
                                evidențierea vizuală a potențialului acesteia?
                              </p>
                            ) : (
                              <p>
                                N-ai timp, e prima experiență sau pur și simplu
                                preferi să faci procesul de vânzare a
                                proprietății tale mai ușor?
                              </p>
                            )}
                          </p>
                        </div>
                        <br />
                        <p>
                          {i === 0 ? (
                            <p>
                              Pachetul de Bază vă aduce{" "}
                              <strong>asistență personalizată avansată</strong>{" "}
                              și furnizează o serie de microservicii
                              profesionale care pregătesc proprietatea dvs.
                              pentru vânzare <strong>în doar 48 de ore</strong>.
                            </p>
                          ) : i === 1 ? (
                            <p>
                              Pachetul Foto/Video îți oferă oportunitatea de a
                              <strong>
                                prezenta profesional proprietatea ta
                              </strong>{" "}
                              prin pregătirea și realizarea de materiale foto și
                              video în doar <strong>3 zile lucrătoare</strong>.
                            </p>
                          ) : (
                            <p>
                              Pachetul Complet Pro{" "}
                              <strong>
                                automatizează complet procesul de vânzare
                              </strong>{" "}
                              a proprietății tale, oferindu-ți asistență
                              personalizată în ceea ce privește{" "}
                              <strong>
                                strategia de preț, prezentarea digitală și
                                promovarea online
                              </strong>
                              .
                            </p>
                          )}
                        </p>
                        <br />
                        <p>
                          {i === 0 ? (
                            <p>
                              Pachetul include o analiză tehnică a proprietății,
                              verificarea documentației acesteia și consultanță
                              specializată pentru a depăși eventualele obstacole
                              de eligibilitate în procesul de vânzare.{" "}
                              <strong>
                                Bazat pe obiectivele dvs. personale
                              </strong>{" "}
                              și pe o analiză detaliată a pieței, vom dezvolta{" "}
                              <strong>trei strategii de vânzare</strong> (RAPID,
                              EFECTIV, PREMIUM), împreună cu o recomandare
                              specializată privind prețul și un{" "}
                              <strong>ghid personalizat</strong> pentru
                              prezentarea și poziționarea proprietății pe piață.
                            </p>
                          ) : i === 1 ? (
                            <p>
                              Pachetul oferă o analiză personalizată pentru
                              prezentarea proprietății tale,{" "}
                              <strong>recomandări specializate</strong> pentru
                              pregătirea sesiunii foto/video și, la cerere,{" "}
                              <strong>
                                servicii de stilizare a interiorului
                              </strong>
                              . Lucrăm în colaborare cu fotografi imobiliari
                              profesioniști și echipamente de{" "}
                              <strong>înaltă calitate</strong>
                              pentru a furniza un pachet foto/video digital ce
                              include 10 fotografii pentru interior, 10 pentru
                              exterior și un <strong>tur virtual</strong> video
                              de 1 minut, editat profesional cu muzică și text.
                              Programarea sesiunii foto/video se ajustează în
                              funcție de condițiile meteo și disponibilitatea
                              vânzătorului.
                            </p>
                          ) : (
                            <p>
                              Pachetul furnizează{" "}
                              <strong>servicii complete</strong> de verificare
                              și prezentare pe piața imobiliară a proprietății
                              tale. De la <strong>asistență juridică</strong> și
                              verificare a documentației până la analiza tehnică
                              a proprietății, prezentare digitală și{" "}
                              <strong>
                                promovare pe cele mai importante platforme
                              </strong>{" "}
                              online de imobiliare. Oferim consultanță
                              specializată și asistență personalizată utilizând
                              tehnologie avansată și{" "}
                              <strong>colaborând cu</strong>{" "}
                              <strong>experți</strong> în domeniul fiscal,
                              rezolvare documente, producție de materiale
                              foto/video și promovare online.
                            </p>
                          )}
                        </p>
                        <br />
                        <div className="row">
                          <div className="col-sm-6">
                            <ul className="rld-list-style mb-3 mb-sm-0">
                              {item.listone.map((list, i) => (
                                <li key={i}>
                                  <i className="fa fa-check" /> {list}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <ul className="rld-list-style">
                              {item.listtwo.map((list, i) => (
                                <li key={i}>
                                  <i className="fa fa-check" />
                                  {list}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="ap2-slider-controls">*/}
              {/*  <div className="rld-slider-extra slider-extra">*/}
              {/*    <div className="text">*/}
              {/*      <span className="first">01 </span>*/}
              {/*      <span className="last" />*/}
              {/*    </div>*/}
              {/*    <div*/}
              {/*      className="ap2-list-progress"*/}
              {/*      role="progressbar"*/}
              {/*      aria-valuemin={0}*/}
              {/*      aria-valuemax={100}*/}
              {/*    >*/}
              {/*      <span className="slider__label sr-only" />*/}
              {/*    </div>*/}
              {/*    <div className="slider-nav rld-control-nav" />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default RoomSpacing;
